import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import './menu.css';
import menuData from './assets/menu.json';
import chickenballs from './assets/chicken-ball.PNG';
import chickerice from './assets/chicken-rice.PNG';
import duck from './assets/duck.PNG';
import rice from './assets/rice.PNG';
import springroll from './assets/spring-roll.PNG';
import sschicken from './assets/sschicken.PNG';
import triangle from './assets/triangle.PNG';


export default function Menu() {
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState("Appetizers");
  

  useEffect(()=> {
    if (location.hash) {
        let elem = document.getElementById(location.hash.slice(1));
        if (elem) {
            elem.scrollIntoView({behavior: "smooth"});
        }
    } else {
        window.scrollTo({top:0,left:0, behavior: "smooth"});
    }
  }, [location]);

  const handleCategoryClick = category => {
    setSelectedCategory(category);
  };

  const renderCategoryButtons = () => {
    if (menuData && Object.keys(menuData).length > 0) {
      return Object.keys(menuData).map((category, index) => {
        return (
          <td key={index}>
            <button className={`category-button ${selectedCategory === category ? 'active' : ''}`} onClick={() => handleCategoryClick(category)}>{category}</button>
          </td>
        );
      });
    }
    return null;
  };

  const renderMenuItems = () => {
    if (menuData && selectedCategory && menuData[selectedCategory]) {
      const items = menuData[selectedCategory];
      if (selectedCategory === "Selected Set Menu") {
        return (
          <div className="menu-group">
            {items.map((item, index) => (
              <div key={index} className="set-menu-item">
                <div className="set-menu-title">{item.id} - {item.servings} - {item.price}</div>
                <ul className="menu-item-list">
                  {item.items.map((setMenuItem, setMenuItemIndex) => (
                    <li key={setMenuItemIndex}>{setMenuItem}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        );
      }
      else if (selectedCategory === "Sauces") {
        return (
          <div className="menu-group">
            {items.map((item, index) => (
              <div key={index} className="menu-item">
                <div className="menu-item-text">
                  <h3>
                    <span>{item.name}</span>
                    <span>{item.price}</span>
                  </h3>
                </div>
              </div>
            ))}
          </div>
        );
      } else {
        return (
          <div className="menu-group">
            {items.map((item, index) => (
              <div key={index} className="menu-item">
                <div className="menu-item-text">
                  <h3>{item.id}</h3>
                  <h3>
                    <span>{item.name}</span>
                    <span>{item.price}</span>
                  </h3>
                  <p className="menu-item-desc">{item.description}</p>
                  {item["sub-class"] === "Spicy" && (
                    <i className="spicy fas fa-pepper-hot" />
                  )}
                  {item["sub-class"] === "Vegetarian" && (
                    <i className="vegetarian fas fa-leaf" />
                  )}
                  {item["sub-class"] === "Spicy & Vegetarian" && (
                    <div>
                      <i className="spicy fas fa-pepper-hot" />
                      <i className="vegetarian fas fa-leaf" />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div>
      <div className="menu-heading">
        <span>Our Menu</span>
      </div>
      <table>
        <tbody>
          <tr className="category-buttons">
            {renderCategoryButtons()}
          </tr>
        </tbody>
      </table>
      <h1 className="menu-group-heading">{selectedCategory}</h1>
      <div className="menu">
        {renderMenuItems()}
      </div>
      <div className="gallery">
        <div className="gallery-item">
          <img src={triangle} alt="Triangles" />
        </div>
        <div className="gallery-item">
          <img src={chickenballs} alt="Chicken Balls" />
        </div>
        <div className="gallery-item">
          <img src={chickerice} alt="Chicken Rice" />
        </div>
        <div className="gallery-item">
          <img src={duck} alt="Duck" />
        </div>
        <div className="gallery-item">
          <img src={springroll} alt="Spring Roll" />
        </div>
        <div className="gallery-item">
          <img src={rice} alt="Rice" />
        </div>
        <div className="gallery-item">
          <img src={sschicken} alt="Sweet Sour Chicken" />
        </div>
      </div>
      <p>The images shown are for illustration purposes only and may not be an exact representation of the product.</p>
      <div className="allergy-box">
        <h3 id="allergy-advice">ALLERGY ADVICE</h3>
        <p>Most of our dishes contain GARLIC, SOYA, GLUTEN, COOKING WINE & SESAME OIL.
          Some of our dishes may contain these 14 allergens as follows:</p>
        <ul>
          <li>Cereals (Containing Gluten)</li>
          <li>Peanuts</li>
          <li>Nuts</li>
          <li>Milk</li>
          <li>Soya</li>
          <li>Mustard</li>
          <li>Lupin</li>
          <li>Eggs</li>
          <li>Fish</li>
          <li>Crustaceans</li>
          <li>Molluscs</li>
          <li>Sesame Seeds</li>
          <li>Celery</li>
          <li>Sulphur Dioxide</li>
        </ul>
      </div>
    </div>
  )
}
