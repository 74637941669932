import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './styles/contactus.css';

export default function Contact() {
    const location = useLocation();

    useEffect(()=> {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1));
            if (elem) {
                elem.scrollIntoView({behavior: "smooth"});
            }
        } else {
            window.scrollTo({top:0,left:0, behavior: "smooth"});
        }
    }, [location]);

    return (
        <div id="contact-us" className="contact-div">
            <div style={{borderBottom: "3px solid black"}}>
                <h1>Contact Us</h1>
            </div>
            <div style={{padding: "20px"}}>
                <p><b>Telephone: 01480 498800</b></p>
                <ul>
                    <li>You can contact us to order food by phone.</li>
                    <li>You can contact us for latest service status.</li>
                    <li>You can contact us directly on any allergy concern you may have.</li>
                </ul>
                <a id="phone" href='tel:01480498800'>Call Us <i className="fas fa-phone" /></a>
            </div>
        </div>
    )
}
