import React from 'react';
import Hero from './components/Hero';
import Info from './components/Info';
import WorkTime from './components/WorkTime';
import Contact from './components/Contact';
import Location from './components/Location';
import Reviews from './components/Reviews';
import Snowflake from './components/Snowflake';

export default function Home() {
  // Get the current date
  const currentDate = new Date();
  // Get the month and day of the month
  const currentMonth = currentDate.getMonth() + 1; // Note: Months are zero-indexed
  const currentDay = currentDate.getDate();

  // Check if it's December 25 or 26
  const isDecember = currentMonth === 12;
  const isChristmas = currentMonth === 12 && (currentDay === 25 || currentDay === 26);

  return (
    <>
    {isDecember ? (<Snowflake /> ) : null}
      <Hero />
      <Info isChristmas={isChristmas} />
      <div className="two-element-grid">
        <WorkTime />
        <Contact />
      </div>
      <Location />
      <Reviews />
    </>
  )
}
