import React from 'react';
import './styles/hero.css';
import logo from '../assets/flaming-wok-icon.svg';
import background from '../assets/food-background.jpg';

export default function Hero() {
    return (
        <section className="hero">
            <img src={background} width="100%" height="450px" style={{filter:"brightness(40%)", position:"absolute", zIndex:"1"}} alt="Food Background" />
            <div className="hero-content">
                <h1 className="hero-title bold-text">Your Go-To Destination for Quick, Tasty Eats</h1>
                <p className="hero-description light-text">
                    "From classic favorites to innovative new creations, our takeaway shop offers something for every taste and occasion.
                </p>
                <p className="hero-description light-text">
                    Stop by today and discover why we're the top choice for delicious, convenient meals."
                </p>
            </div>
            <div className="hero-image-container">
                <img src={logo} alt="Chef Logo" className="hero-image" />
            </div>
        </section>
    )
}
