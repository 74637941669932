import React from 'react';
import './styles/snowflake.css';

const Snowflake = () => {
  return (
    <div className="snowflakes" aria-hidden="true">
        <div className="snowflake">
            <div className="inner">❅</div>
        </div>
        <div className="snowflake">
            <div className="inner">❅</div>
        </div>
        <div className="snowflake">
            <div className="inner">❅</div>
        </div>
        <div className="snowflake">
            <div className="inner">❅</div>
        </div>
        <div className="snowflake">
            <div className="inner">❅</div>
        </div>
        <div className="snowflake">
            <div className="inner">❅</div>
        </div>
        <div className="snowflake">
            <div className="inner">❅</div>
        </div>
        <div className="snowflake">
            <div className="inner">❅</div>
        </div>
        <div className="snowflake">
            <div className="inner">❅</div>
        </div>
        <div className="snowflake">
            <div className="inner">❅</div>
        </div>
        <div className="snowflake">
            <div className="inner">❅</div>
        </div>
        <div className="snowflake">
            <div className="inner">❅</div>
        </div>
    </div>
  );
};

export default Snowflake;