import React from 'react';
import './styles/worktime.css';
import clock from '../assets/clock.svg';

export default function WorkTime() {
  return (
    <div className="hour-div">
      <div style={{borderBottom: "3px solid black", margin: "5px 5%"}}>
        <img src={clock} width="36" height="36" style={{position: "relative", margin: "0px 10px 0px 20px", bottom: "-7px"}} alt="Clock"/>
        <h1 style={{display: "inline-block"}}>Business Hours</h1>
      </div>
      <div className="time-grid">
        <div className="time-item">
          <h2>Monday</h2>
          <p>04:30pm - 10:00pm</p>
        </div>
        <div className="time-item">
          <h2>Tuesday</h2>
          <p>CLOSED</p>
        </div>
        <div className="time-item">
          <h2>Wednesday</h2>
          <p>04:30pm - 10:00pm</p>
        </div>
        <div className="time-item">
          <h2>Thursday</h2>
          <p>04:30pm - 10:00pm</p>
        </div>
        <div className="time-item">
          <h2>Friday</h2>
          <p>04:30pm - 10:30pm</p>
        </div>
        <div className="time-item">
          <h2>Saturday</h2>
          <p>04:30pm - 10:30pm</p>
        </div>
        <div className="time-item">
          <h2>Sunday</h2>
          <p>04:30pm - 10:00pm</p>
        </div>
      </div>
    </div>
  )
}
