import React from 'react';
import './styles/review.css';

export default function Reviews() {
  return (
    <div className="review-box">
      <div className="taster-heading">
        <h1 id="taster-title">Taster Says</h1>
      </div>
      <div className="taster-container">
        <div className="card">
          "Dropped in on my way home for a tasty Chinese takeaway, 
          plenty of choice as usual and my order was all cooked in good time. 
          Didn't have to wait long. It's cash only, but there's a cash point just around the corner near the Spar shop"
          <div className="from-right">
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
          </div>
        </div>
        <div className="card">
          "First time going here and I was impressed, food was very nice, 
          wasn't greasy like others and portions were really descent size will definitely be a regular now thanks"
          <div className="from-right">
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
          </div>
        </div>
        <div className="card">
          "Delicious! Just moved to the area and ordered take out for four. 
           We are a family of vegans and this food was delicious . Thank you"
           <div className="from-right">
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            </div>
        </div>
        <div className="card">
          "Had a great meal every time, would definitely recommend this Chinese takeaway, 
          given them a call and fresh food is always ready when you arrived! 
          This year they seems like they will be open during Christmas Day. A good meal on the Christmas Holiday! Perfect!"
          <div className="from-right">
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
          </div>
        </div>
        <div className="card">
          "I recently purchases a 'Meal for One' Set Menu that was both wholesome as well as delicious with a good variety of items.
          Whilst I gave the Wok a poor review for its cold chips and attitude to my complaint 5 years ago, 
          I have now deleted that review in the light of my new enjoyable experience - my first return in 5 years. 
          I will be back for more.  Highly Recommended."
          <div className="from-right">
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
          </div>
        </div>
        <div className="card">
          "We have been using the Flaming Wok for years and never had a bad meal. 
          Love the set where you're almost on top of the kitchen and can see the chef's cooking your food. 
          A popular Chinese slightly out of town so more benefitial for the residents rather than town centre. 
          Definitely recommended"
          <div className="from-right">
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
            <i className="fa fa-star checked"></i>
          </div>
        </div>
      </div>  
    </div>
  )
}
