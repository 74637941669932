import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles/header.css';
import flamingwokText from '../assets/flaming-wok.png';


export default function Header() {
  const [collapsed, setCollapsed] = useState(false);

  const handleClick = () => setCollapsed(!collapsed);
  const closeMobileMenu = () => setCollapsed(false);

  return (
    <header>
      {/* NAVBAR AT 768PX & ABOVE */}
      {/* HAMBURGER MENU AT 768PX & LOWER */}
      <div className="navbar-container">
        <img src={flamingwokText} width="100%" height="100%" alt="Flaming Wok Text" />
        <div className='menu-icon' onClick={handleClick}>
          <i className={collapsed ? "fas fa-times" : "fas fa-bars"} />
        </div>
      </div>
      <nav className="navigation">
        <div className={collapsed ? "nav-menu active" : "nav-menu"}>
          <Link to='/'>
            <button className="nav-item bold-text" onClick={closeMobileMenu}>Home</button>
          </Link>
          <Link to='/menu'>
            <button className="nav-item bold-text" onClick={closeMobileMenu}>Menu</button>
          </Link>
          <Link to='/#contact-us'>
            <button className="nav-item bold-text" onClick={closeMobileMenu}>Contact Us</button>
          </Link>
        </div>
      </nav>
    </header>
  )
}

