import React from 'react';
import { Link } from 'react-router-dom';
import './styles/footer.css';

export default function Footer() {
  return (
    <footer>
      <h2 className="underline-box bold-text">Flaming Wok Chinese Takeaway</h2>
      <div className="column-box">
        <div>
          <h2 className="bold-text">Address</h2>
          <p className="light-text">80 Ramsey Rd, Saint Ives
              Cambridgeshire, PE27 3XW, GB</p>
        </div>
        <div>
          <h2 className="bold-text">About us</h2>
          <p className="light-text">We provide Peking, Szechuan & Cantonese Food</p>
          <Link to='/menu#allergy-information' style={{textDecoration: "none"}}>
            <p className="light-text" style={{textDecoration: "underline", textUnderlineOffset: "3px"}}>Allergy Information</p>
          </Link>
        </div>
      </div>
      <div id="copy-right" className="light-text">© 2022 Flaming Wok Chinese Takeaway</div>
    </footer>
  )
}
