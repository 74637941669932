import React from 'react';
import './styles/location.css';
import L from 'leaflet';
import logo from '../assets/flaming-wok-icon.svg';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

export default function Location() {
  const position = [52.33594, -0.07738];

  const customIcon = new L.Icon({
    iconUrl: logo,
    iconSize: [60, 120],
    iconAnchor: [30, 70],
    popupAnchor: [0, -40],
  });
  
  return (
    <div className="side-border-grid">
      <MapContainer center={position} minZoom={15} zoom={21} scrollWheelZoom={false} style={{ width: "100%", height: "60vh" }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <Marker position={position} icon={customIcon}>
          <Popup>
            80 Ramsey Rd, St. Ives PE27 3XW, UK<br></br>
            <a href="https://www.google.com/maps/place/Flaming+Wok/@52.3359129,-0.0796009,17z/data=!3m1!4b1!4m5!3m4!1s0x4877de6beb5dd46d:0xa31cbace8855903a!8m2!3d52.3359129!4d-0.0774122">Check Google Map</a>
          </Popup>
        </Marker>
      </MapContainer>
      <div style={{background:"white", padding:"20px"}}>
        <h2 style={{textDecoration:"underline"}}>Store Location</h2>
        <b>Address: 80 Ramsey Rd, St. Ives PE27 3XW</b>
      </div>
      
    </div>
  )
}
