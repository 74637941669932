import React from 'react';
import aromaticDuck from '../assets/aromatic-duck.png';
import chowMein from '../assets/chow-mein.jpg';
import friedRice from '../assets/fried-rice.jpg';
import ssChicken from '../assets/sweetsour-chicken.jpg';
import open from '../assets/open.svg';
import closed from '../assets/closed.svg';
import './styles/info.css';

export default function Info({ isChristmas }) {
  return (
    <div>
      <div className="image-grid">
        <div className="image-overlay">
          <img src={aromaticDuck} width="100%" height="100%" alt="Duck" />
          <div className="image-text">
            Aromatic duck: juicy, succulent duck meat, wrapped in a thin pancake with cucumber and sweet bean sauce. A must-try Chinese specialty!
          </div>
        </div>
        <div className="image-overlay">
          <img src={chowMein} width="100%" height="100%" alt="Chow Mein" />
          <div className="image-text">
            Delicious stir-fried noodles with your choice of meat and vegetables, seasoned with a savory sauce. Perfect for a quick and satisfying meal.
          </div>
        </div>
        <div className="image-overlay">
          <img src={friedRice} width="100%" height="100%" alt="Fried Rice" />
          <div className="image-text">
            Perfectly seasoned rice stir-fried with vegetables and meat. A classic and satisfying dish, quick and easy to enjoy!
          </div>
        </div>
        <div className="image-overlay">
          <img src={ssChicken} width="100%" height="100%" alt="Sweet Sour Chicken" />
          <div className="image-text">
            Crispy chicken coated in a tangy and sweet sauce, served with fresh vegetables. A crowd-pleaser, perfect for satisfying those sweet and sour cravings!
          </div>
        </div>
      </div>
      <div className="info-div">
        <div>
          <p>Our Cuisines: Peking, Szechuan & Cantonese</p>
          <p>Service Status:</p>
          {isChristmas ? (
            <>
            <img src={closed} width="75" height="41" alt="closed" style={{display:"inline", margin:"0 10px"}} />
            <p style={{display:"inline"}}>on 25/26th December</p>
            </>) : (
              <>
              <img src={open} width="75" height="41" alt="open" style={{display:"inline", margin:"0 10px"}} />
              <p style={{display:"inline"}}>as Normal</p>
              </>
            )}
          <p>Payment Option: Cash Only</p>
          <p>Delivery Option: Sorry, We Don't Do Delivery!</p>
        </div>
        <div>
          <p>Specials and promotions!</p>
          <p>Try our Special Chow Mein, Special Fried Rice and Special Noodles!</p>
        </div>
      </div>
    </div>
  )
}
